import React, { Component } from "react";
import './App.css';

// Components:
import ScrollToTop from './ScrollToTop'
import Welcome from './Components/Welcome';
import Menu from './Components/Menu';
import MenuItem from './Components/MenuItem';
import Basket from './Components/Basket';
import Login from './Components/Login';
import Payment from './Components/Payment';
import Tab from './Components/Tab';
import { Route } from 'react-router-dom';

// Main parent component that gets imported into index.js
class App extends Component {

  constructor(props) {
    super(props)
    this.updateOrder = this.updateOrder.bind(this)
    this.login = this.login.bind(this)
    this.addToTab = this.addToTab.bind(this)
    this.clearOrders = this.clearOrders.bind(this)
  }

  state = {
    user: { name: "Jane Doe" , username: "", password: "" },
    table: 1,
    tab: [],
    orders: [],
    menu: {
      "Breakfast": [
        { id: "b1", name: "Banana bread", price: 6.00, quantity: 0, url: '/banana-bread.jpg', description: "Toasted with butter" },
        { id: "b2", name: "Sourdough toast", price: 6.00, quantity: 0, url: '/sourdough-toast.jpg', description: "Your choice of vegemite, peanut butter, nutella or the sticky pot jams" },
        { id: "b3", name: "Ham + cheese croissant", price: 8.50, quantity: 0, url: '/ham-and-cheese.jpg', description: "Double smoked ham + provolone cheese" },
        { id: "b4", name: "Chia bowl", price: 15.00, quantity: 0, url: '/chia-bowl.jpg', description: "Coconut soaked chia with apple, seeds, natural yogurt, berry compote + mint" },
        { id: "b5", name: "Maple-baked granola", price: 15.00, quantity: 0, url: '/maple-granola.jpg', description: "House-made mix with blue spirulina, yogurt, berry & mint" }
      ],
      "Little Rowers": [
        { id: "lr1", name: "Mini bacon + egg roll", price: 9.00, quantity: 0, url: '/bacon-egg-roll.jpg', description: "With tomato sauce" },
        { id: "lr2", name: "Eggs their way + toast", price: 8.00, quantity: 0, url: '/eggs-on-toast.jpg', description: "Poached, fried or scrambled on sourdough" },
        { id: "lr3", name: "Yogurt + berry compote", price: 8.00, quantity: 0, url: '/yogurt-berry-compote.jpg', description: "" }
      ],
      "Drinks": [
        { id: "sc1", name: "Home-made lemon iced tea", price: 7.00, quantity: 0, url: '/lemon-iced-tea.jpg', description: "" },
        { id: "sc2", name: "Ice coffee / Iced mocha / Iced chocolate", price: 8.00, quantity: 0, url: '/ice-coffee.jpg', description: "" },
        { id: "sc3", name: "Smoothie", price: 8.50, quantity: 0, url: '/smoothie.jpg', description: "Banana, berry and mango" },
        { id: "sc4", name: "Milkshake", price: 7.00, quantity: 0, url: '/milkshake.jpg', description: "Vanilla, caramel, chocolate and strawberry" }
      ]
    },
    staff: [
      { role: "chef", name: "Sam", img: "/chef.jpg", tip: 0, rating: 0 },
      { role: "server", name: "Ashley", img: "/waitress.jpg", tip: 0, rating: 0 },
      { role: "server", name: "Kyle", img: "/waiter.jpg", tip: 0, rating: 0 },
    ]
  }

  login = (loginUser) => {
    this.setState({
      user: loginUser
    })
  }

  /* This function is passed down to the Payment component.
  It will allow you to create a new object that tracks which user made the order and what food items are in that order */
  addToTab = (newOrder) => {
    let newTab = this.state.tab;
    newTab.push(newOrder);
    this.setState({
      tab: newTab
    })
  }

  /* Function that's needed to clear the Orders array and menu order quantities. This should happen once an order has been sent to the kitchen and can no longer be changed. */
  clearOrders = () => {
    let blankArray = [];
    this.setState({
      orders: blankArray,
      menu: {
        "Breakfast": [
          { id: "b1", name: "Banana bread", price: 6.00, quantity: 0, url: '/banana-bread.jpg', description: "Toasted with butter" },
          { id: "b2", name: "Sourdough toast", price: 6.00, quantity: 0, url: '/sourdough-toast.jpg', description: "Your choice of vegemite, peanut butter, nutella or the sticky pot jams" },
          { id: "b3", name: "Ham + cheese croissant", price: 8.50, quantity: 0, url: '/ham-and-cheese.jpg', description: "Double smoked ham + provolone cheese" },
          { id: "b4", name: "Chia bowl", price: 15.00, quantity: 0, url: '/chia-bowl.jpg', description: "Coconut soaked chia with apple, seeds, natural yogurt, berry compote + mint" },
          { id: "b5", name: "Maple-baked granola", price: 15.00, quantity: 0, url: '/maple-granola.jpg', description: "House-made mix with blue spirulina, yogurt, berry & mint" }
        ],
        "Little Rowers": [
          { id: "lr1", name: "Mini bacon + egg roll", price: 9.00, quantity: 0, url: '/bacon-egg-roll.jpg', description: "With tomato sauce" },
          { id: "lr2", name: "Eggs their way + toast", price: 8.00, quantity: 0, url: '/eggs-on-toast.jpg', description: "Poached, fried or scrambled on sourdough" },
          { id: "lr3", name: "Yogurt + berry compote", price: 8.00, quantity: 0, url: '/yogurt-berry-compote.jpg', description: "" }
        ],
        "Drinks": [
          { id: "sc1", name: "Home-made lemon iced tea", price: 7.00, quantity: 0, url: '/lemon-iced-tea.jpg', description: "" },
          { id: "sc2", name: "Ice coffee / Iced mocha / Iced chocolate", price: 8.00, quantity: 0, url: '/ice-coffee.jpg', description: "" },
          { id: "sc3", name: "Smoothie", price: 8.50, quantity: 0, url: '/smoothie.jpg', description: "Banana, berry and mango" },
          { id: "sc4", name: "Milkshake", price: 7.00, quantity: 0, url: '/milkshake.jpg', description: "Vanilla, caramel, chocolate and strawberry" }
        ]
      }
    })
  }

  // Clear the TAB after customer has paid and tipped. Order is now complete and finished.
  clearTab = () => {
    this.setState({
      tab: []
    })
  }

  // Function that's to be passed to MenuItem.js that adds or removes orders
  updateOrder = (newOrder) => {
    let newOrders = this.state.orders;
    // If the id of the newOrder exists in the orders array (means the food has already been ordered previously), then the function should UPDATE the matching order, not just PUSH to the array
    if (newOrders.find(food => food.id === newOrder.id)) {
      let foodIndex = newOrders.findIndex(food => food.id === newOrder.id);
      // if the newOrder's quantity has been changed to ZERO (customer no longer wants it) then remove the food from the orders array
      if (newOrder.quantity === 0) {
        newOrders.splice(foodIndex, 1);
        this.setState({
          orders: newOrders
        })
      } else {
        // if the newOrder's quantity is anything larger than 0, update the food item in the orders array with the new updated one
        newOrders[foodIndex] = newOrder;
        this.setState({
          orders: newOrders
        })
      }
    } else {
      // If the newOrder food hasn't been ordered yet (you can't find a matching id in the orders array) then simply push the order into the orders array
      if (newOrder.quantity > 0) {
        newOrders.push(newOrder);
        this.setState({
          orders: newOrders
        })
      }
    }
  }

  render() {

    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    }

    let totalTabCost = 0;
    this.state.tab.forEach(el => {
      totalTabCost += el.orders.reduce((acc, currentVal) => acc + (currentVal.price*currentVal.quantity), 0)
    })

    return (
      <ScrollToTop>
        <div className="App">
          <Route exact path='/' render={(props) => < Welcome {...props} />}>
          </Route>

          <Route exact path='/menu' render={(props) => < Menu {...props} menu={this.state.menu} orders={this.state.orders} />}>
          </Route>

          <Route exact path='/menu/:id' render={(props) => < MenuItem {...props} menu={this.state.menu} orders={this.state.orders} updateOrder={this.updateOrder} />}>
          </Route>

          <Route exact path='/basket' render={(props) => < Basket {...props} menu={this.state.menu} orders={this.state.orders} user={this.state.user} />}>
          </Route>

          <Route exact path='/login' render={(props) => < Login {...props} menu={this.state.menu} orders={this.state.orders} login={this.login} />}>
          </Route>

          <Route exact path='/payment' render={(props) => < Payment {...props} menu={this.state.menu} orders={this.state.orders} user={this.state.user} addToTab={this.addToTab} clearOrders={this.clearOrders} />}>
          </Route>

          <Route exact path='/table' render={(props) => < Tab {...props} table={this.state.table} tab={this.state.tab} staff={this.state.staff} clearTab={this.clearTab} totalTabCost={totalTabCost} />}>
          </Route>
        </div>
      </ScrollToTop>
    );

  }
}

export default App; 