import React, { Component } from "react";
import './Login.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

// Menu list showing all the items
class Login extends Component {

  state = {
      user : {
          name: "Jane Doe",
          username: "",
          password: ""
      }
  }

    goBack = () => {
        this.props.history.goBack();
    }

    login = () => {
        if (this.state.user.username === "" || this.state.user.password === "") {
            this.props.login({
                name: "Jane Doe",
                username: "Guest",
                password: "password"
            })
        } else {
            this.props.login(this.state.user);
        }
        console.log("Login success: ");
        this.props.history.push('/payment');
    }

    guestLogin = () => {
        this.props.login({
            name: "Jane Doe",
            username: "Guest",
            password: "password"
        });
        console.log("Guest login success: ");
        this.props.history.push('/payment');
    }

    handleChange(event) {
        let { name, value } = event.target;
        let currentUser = this.state.user;
        currentUser[name] = value;
        this.setState({
            user: currentUser
        })
    }

  render() {
    return (
      <section className="">
        {/* HEADER */}
        <div className="header">
            <Row className="">
                <Col xs={3} className="justify-center">
                    <img className="goBack arrow" onClick={this.goBack} src='/left-arrow-green.png' alt="back-arrow"/>
                </Col>
                <Col xs={6} className="justify-center">
                    <h2 className="">Login</h2>
                </Col>
                <Col xs={3} className="">
                </Col>
            </Row>
        </div>

        {/* BODY CONTENT */}
        <div className="login-form p1">
            <label>Username:</label>
            <input type="text" name="username" className="username" value={this.state.username} placeholder="user" onChange={(e) => this.handleChange(e)} />
            <label>Password:</label>
            <input type="password" name="password" className="password" value={this.state.password} placeholder="********" onChange={(e) => this.handleChange(e)} />
            <div className="login-buttons">
                <Button variant="success" onClick={()=>this.login()}>Login</Button>
                <Button variant="primary" onClick={()=>this.login()}>Signup</Button>
            </div>
        </div>

        <div className="text-center p1">Or continue via the following</div>

        <div className="social-logins p1">
            <div className="facebook">
                <img src="/facebook.png" alt="facebook-logo" className="logo" onClick={() => this.guestLogin()} />
            </div>
            <div className="google">
                <img src="/google.png" alt="google-logo" className="logo" onClick={() => this.guestLogin()} />
            </div>
            <Button variant="success" onClick={() => this.guestLogin()} >Guest account</Button>
        </div>

        <div className="text-center p1">
            <small>By continuing you agree to our Terms and Conditions</small>
            <br/>
            <small>Please read our Privacy Policy and Cookie Policy before proceeding</small>
        </div>
      </section>
    );
  }
}

export default Login;