import React, { Component } from "react";
import './Basket.css';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// List of all your current Basket items
class Basket extends Component {

  state = {
  }

  goBack = () => {
      this.props.history.goBack();
  }

  render() {
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    }

    let totalCost = 0;
    this.props.orders.forEach(order => {
      totalCost += order.price * order.quantity
    })
    return (
    <section className="basketList">
      <div className="header">
        <Row>
            <Col xs={3} className="justify-center" >
              <img className="goBack arrow" onClick={this.goBack} src='/left-arrow-green.png' alt="back-arrow"/>
            </Col>
            <Col xs={6} className="justify-center">
                <h2 className="text-center">Your Basket</h2>
            </Col>
            <Col xs={3} className="">
            </Col>
        </Row>
      </div>

      {/* List of order items. Shows statement if basket is empty. */}
      <div className="subheading">
        <h4>Your Order</h4>
      </div>
      <div className="bubble-group">
      { (this.props.orders.length > 0) ? 
      this.props.orders.map((item) => 
        <Link to={`/menu/${item.id}`} className="food-item highlight-bg" key={item.id}>
          {(this.props.orders.find(el => el.id === item.id) ?
            <div className="food-item-quantity">{this.props.orders.find(el => el.id === item.id).quantity}</div>
            :
            <div className="food-item-quantity-zero">0</div>
          )}
          <div className="food-item-info">
            <div className="food-item-name">{item.name} (${item.price.toFixed(2)} ea)</div>
            <div>${(item.quantity * item.price).toFixed(2)}</div>
          </div>
        </Link>
      )
      :
      <div className="food-item justify-center" style={{color: 'grey'}}>Your order basket is empty</div>
      }
      </div>


      {/* Lists upsell items */}
      <br/>
      <div className="subheading">
        <h4>People's favourites</h4>
      </div>
      <div className="bubble-group">
        {this.props.menu.Drinks.map((item) => 
          <Link to={`/menu/${item.id}`} className="food-item" key={item.id}>
            <div className="food-item-info">
              <div className="food-item-name">{item.name}</div>
              <div>${item.price.toFixed(2)}</div>
            </div>
          </Link>
        )}
      </div>

      <div className="fixed-footer">
        { (this.props.orders.length > 0) ?
        <Button variant="success">
          <Link to={(this.props.user.username === "") ? `/login` : `/payment`}>
              <div>Confirm Order</div>
              <div>${totalCost.toFixed(2)}</div>
          </Link>
        </Button>
        :
        <Button disabled variant="secondary">
          <div>
              <div>Empty Basket</div>
              <div>${totalCost.toFixed(2)}</div>
          </div>
        </Button>
        }
      </div>
    </section>
    );
  }
}

export default Basket;