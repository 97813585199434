import React, { Component } from "react";
import './Welcome.css';

import Spinner from 'react-bootstrap/Spinner';

// Menu list showing all the items
class Welcome extends Component {

  state = {
  }

  render() {
      
    console.log("WELCOME SCREEN: ", this.props)
    // Loadscreen shows for a little while to show 'loading' animation, then redirects to menu page
    setTimeout(() => {this.props.history.push('/menu')}, 5000)
    
    return (
      <div className="welcome">
          <img className="mrc-logo" src="/mrc-logo-red.png" alt="Mosman rowing club logo" />
          <img className="servr-logo" src="/servr-logo.png" alt="servr-main-logo" />
          <img className="servr-icon" src="/servr-icon.png" alt="servr-main-logo" />
          <div className="loading-spinner">
            <Spinner animation="border" variant="success" />
          </div>
      </div>
    );
  }
}

export default Welcome;