import React, { Component } from "react";
import './Menu.css';

import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';

// Menu list showing all the items
class Menu extends Component {

  state = {
  }

  seeTab = () => {
    this.props.history.push('/table');
  }

  render() {
    let menu = this.props.menu;

    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    }

    let totalCost = 0;
    this.props.orders.forEach(order => {
      totalCost += order.price * order.quantity
    })

    // FOOTER BUTTON TO PLACE ORDER
    let orderButton;
    if (totalCost === 0) {
      orderButton =
      <Button variant="secondary">
        <div>
          <div>Basket empty</div>
          <div>${totalCost.toFixed(2)}</div>
        </div>
      </Button>
    } else {
      orderButton = 
      <Button variant="success">
        <Link to='/basket'>
          <div>Place order</div>
          <div>${totalCost.toFixed(2)}</div>
        </Link>
      </Button>
    }
    
    return (
      <section className="menu">
          {/* <div className="menu-banner" style={{background: 'url(/MosmanRowers-bg.jpg)'}}> */}
          <div className="menu-banner">
            <div className="menu-banner-overlay">
              <img src="/mrc-logo-white.png" alt="MRC Logo"/>
              <h1>Mosman Rowers Club</h1>
              <Button variant="info" className="table-button" onClick={this.seeTab}>View Table 1</Button>
            </div>
            <div className="servr-branding">
              <img src="/servr-icon.png" alt="servr-icon" />
            </div>
          </div>
          {/* <div className="header">
            <Row className="">
              <Col xs={2} className=""></Col>
              <Col xs={8} className="text-center">
                  <h1 className="">Mosman Rowers Dining Menu</h1>
              </Col>
              <Col xs={2} className=""></Col>
            </Row>
          </div> */}
          <div className="subheading">
            <h4>Breakfast</h4>
          </div>
          <div className="bubble-group">
            {menu['Breakfast'].map((item) => 
            <Link to={`/menu/${item.id}`} className="food-item" key={item.id}>
              {(this.props.orders.find(el => el.id === item.id) ?
                <div className="food-item-quantity">{this.props.orders.find(el => el.id === item.id).quantity}</div>
                :
                <div className="food-item-quantity-zero">0</div>
              )}
              <div className="food-item-info">
                <div className="food-item-name">{item.name}</div>
                <div>${item.price.toFixed(2)}</div>
              </div>
            </Link>
            )}
          </div>

          <div className="subheading">
            <h4>Little Rowers</h4>
          </div>
          <div className="bubble-group">
            {menu['Little Rowers'].map((item) => 
            <Link to={`/menu/${item.id}`} className="food-item" key={item.id}>
              {(this.props.orders.find(el => el.id === item.id) ? 
                <div className="food-item-quantity">{this.props.orders.find(el => el.id === item.id).quantity}</div>
                :
                <div className="food-item-quantity-zero">0</div>
              )}
              <div className="food-item-info">
                <div className="food-item-name">{item.name}</div>
                <div>${item.price.toFixed(2)}</div>
              </div>
            </Link>
            )}
          </div>

          <div className="subheading">
            <h4>Drinks</h4>
          </div>
          <div className="bubble-group">
            {menu['Drinks'].map((item) => 
            <Link to={`/menu/${item.id}`} className="food-item" key={item.id}>
              {(this.props.orders.find(el => el.id === item.id) ?
                <div className="food-item-quantity">{this.props.orders.find(el => el.id === item.id).quantity}</div>
                :
                <div className="food-item-quantity-zero">0</div>
              )}
              <div className="food-item-info">
                <div className="food-item-name">{item.name}</div>
                <div>${item.price.toFixed(2)}</div>
              </div>
            </Link>
            )}
          </div>

          <div className="fixed-footer">
            {orderButton}
          </div>
      </section>
    );
  }
}

export default Menu;