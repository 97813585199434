import React, { Component } from "react";
import './Payment.css';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';

// Menu list showing all the items
class Payment extends Component {

  state = {
      showPopup: false,
  }

    goBack = () => {
        this.props.history.goBack();
    }

    backToMenu = () => {
        this.props.history.push('/menu')
    }

    handleChange(event) {
        let { name, value } = event.target;
        let currentUser = this.state.user;
        currentUser[name] = value;
        this.setState({
            user: currentUser
        })
    }

    // We must create a new object which represents a new order. It should contain the user's name and the array of food items in their order instance.
    // Had to make async 
    addToTab = () => {
        this.setState({showPopup: true});
        let newOrder = {
            user: this.props.user.name,
            orders: this.props.orders,
            counter: 0,
            timer: setInterval(function(){
                newOrder.counter += 0.5;
                if (newOrder.counter === 100){
                    clearInterval(newOrder.timer)
                }
            }, 250),
        }
        this.props.addToTab(newOrder);
        setTimeout(()=>{
            this.setState({showPopup: false});
            this.props.clearOrders();
            this.props.history.push('/table');
        }, 2500)
    }

  render() {
    let totalCost = 0;
    this.props.orders.forEach(order => {
    totalCost += order.price * order.quantity
    })

    return (
      <section className="">
        {/* HEADER */}
        <div className="header">
            <Row className="">
                <Col xs={3} className="justify-center">
                    <img className="goBack arrow" onClick={this.goBack} src='/left-arrow-green.png' alt="back-arrow"/>
                </Col>
                <Col xs={6} className="justify-center">
                    <h2 className="">Payment</h2>
                </Col>
                <Col xs={3} className="">
                </Col>
            </Row>
        </div>

        <div className="subheading text-center bubble-group highlight-bg">
            <h5>Hi {this.props.user.name}</h5>
            <br/>
            <h6 className="bold">Your total order is: <span className="color-main">${totalCost.toFixed(2)}</span></h6>
        </div>

        <div className="subheading text-center">
            <h5>How would you like to pay?</h5>
        </div>

        <div className="px1">
            <Accordion defaultActiveKey="0">
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="0">
                        <div className="payment-icons">
                            <span>Pay with Credit Card</span>
                            <img src="/visa.svg" alt="visa"/>
                            <img src="/mastercard.svg"  alt="mastercard"/>
                        </div>
                        <img className="down-arrow" src="/down-arrow.png" alt="down-arrow"></img>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body>
                            <p>Card Number</p>
                            <p className="placeholder-info">XXXX XXXX XXXX XXXX</p>
                            <p>Expiry Date</p>
                            <div className="flex">
                                <p className="placeholder-info date">MM</p>
                                <p className="placeholder-info date">YY</p>
                            </div>
                            <p>Security Number</p>
                            <p className="placeholder-info">XXX</p>
                            <p>Carholder Name</p>
                            <p className="placeholder-info">Jane Doe</p>
                            <Button variant="success" className="" onClick={() => {this.addToTab()}} >Pay with this method</Button>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="1">
                        <div className="payment-icons">
                            <span>Pay with PayPal</span>
                            <img src="/paypal.svg" alt="paypal"/>
                        </div>
                        <img className="down-arrow" src="/down-arrow.png" alt="down-arrow"></img>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="1">
                        <Card.Body>
                            <p>PayPal login and synchronization</p>
                            <Button variant="success" className="" onClick={() => {this.addToTab()}} >Pay with this method</Button>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="2">
                        <div className="payment-icons">
                            <span>Pay with Google Pay</span>
                            <img src="/googlepay.svg" alt="google-pay"/>
                        </div>
                        <img className="down-arrow" src="/down-arrow.png" alt="down-arrow"></img>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="2">
                        <Card.Body>
                            <p>Google Pay app synchronization</p>
                            <Button variant="success" className="" onClick={() => {this.addToTab()}} >Pay with this method</Button>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="3">
                        <div className="payment-icons">
                            <span>Pay with Apple Pay</span>
                            <img src="/applepay.svg" alt="apple-pay"/>
                        </div>
                        <img className="down-arrow" src="/down-arrow.png" alt="down-arrow"></img>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="3">
                        <Card.Body>
                            <p>Apple Play app synchronization</p>
                            <Button variant="success" className="" onClick={() => {this.addToTab()}} >Pay with this method</Button>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </div>

        {/* MODAL POPUP CONFIRMING PURCHASE */}
        <Modal
        show={this.state.showPopup}
        backdrop="static"
        keyboard={false}
        centered
        >
            <Modal.Header>
                <Modal.Title>Order successful!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Thank you for making an order with us.
            </Modal.Body>
            <Modal.Body>
                We'll have your order to you as soon as possible.
            </Modal.Body>
            <Modal.Footer>
                <Spinner animation="border" variant="success" />
            </Modal.Footer>
        </Modal>
      </section>
    );
  }
}

export default Payment;