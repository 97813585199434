import React, { Component } from "react";
import './Tab.css';

import OrderStatus from './OrderStatus';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Rating from '@material-ui/lab/Rating';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
// Menu list showing all the items
class Table extends Component {

    state = {
        showVerify: false,
        showTip: false,
        showFarewell: false,
        staff: [
            { role: "chef", name: "Sam", img: "/chef.jpg", tip: 0, rating: 0 },
            { role: "server", name: "Ashley", img: "/waitress.jpg", tip: 0, rating: 0 },
            { role: "server", name: "Kyle", img: "/waiter.jpg", tip: 0, rating: 0 },
        ],
        tip: 0
    }
  
    backToMenu = () => {
        this.props.history.push('/menu')
    }

    handleShowVerify = () => {
        this.setState({
            showVerify: true
        })
    }
    handleCloseVerify = () => {
        this.setState({
            showVerify: false
        })
    }
    handleShowTip = () => {
        this.setState({
            showTip: true,
            showVerify: false
        })
    }
    handlePayTip = () => {
        console.log("TIPPED THE RELEVANT STAFF");
        this.setState({
            showTip: false,
            showFarewell: true
        })
    }
    handleCloseTip = () => {
        this.setState({
            showTip: false
        })
    }
    handleCloseFarewell = () => {
        this.setState({
            showFarewell: false
        })
        this.props.clearTab();
        // Popup fades away and after a small delay, redirects you to the main Menu
        setTimeout(() => this.props.history.push('/menu'), 1000)
    }
    
    handleTip = (event, newTip) => {
        let staffMember = this.state.staff.find(el => el.name === event.target.name);
        if (staffMember) { 
            staffMember.tip = newTip 
            let staffIndex = this.state.staff.findIndex(el => el.name === event.target.name);
            let newStaff = this.state.staff;
            newStaff[staffIndex] = staffMember;
            if (newTip) {
                this.setState({
                    staff: newStaff
                });
            }
        }
        console.log("Staff objects: ", this.state.staff)
    };

    render() {
        return (
            <section className="tab">
                {/* HEADER */}
                <div className="header">
                    <Row className="">
                        <Col xs={3} className="justify-center">
                            <div className="goBack" onClick={() => this.backToMenu()}>
                                <img src='/menu.png' className="menu-icon" alt="menu-icon"/>
                                <span>Menu</span>
                            </div>
                        </Col>
                        <Col xs={6} className="justify-center">
                            <h2 className="">Table {this.props.table}</h2>
                        </Col>
                        <Col xs={3} className="">
                        </Col>
                    </Row>
                </div>
                {/* Show user's orders */}
                <div className="subheading">
                    <h4 className="text-center">Your Orders</h4>
                </div>
                { (this.props.tab.length > 0) ? 
                    this.props.tab.slice(0).reverse().map((el, index) => (
                        <div className="bubble-group table-order" key={index}>
                            <div className="order-heading flex justify-between align-center">
                                <h5 className="">{el.user}</h5>
                                < OrderStatus order={el} />
                            </div>
                            {el.orders.map(food => (
                                <div className="food-item" key={food.id}>
                                    <div className="food-item-quantity">{food.quantity}</div>
                                    <div className="food-item-info">
                                        <div className="food-item-name">{food.name} (${food.price.toFixed(2)} ea)</div>
                                        <div>${(food.quantity * food.price).toFixed(2)}</div>
                                    </div>
                                </div>
                            ))}
                            <div className="food-item justify-center bold">Total: <span className="color-main" style={{marginLeft:"0.25rem"}}>${el.orders.reduce((acc, currentVal) => acc + (currentVal.price*currentVal.quantity), 0).toFixed(2)}</span></div>
                        </div>
                    ))
                :
                <div className="food-item justify-center text-center" style={{color: 'grey'}}>You have not ordered yet for this table. <br/> Please view the menu for options.</div>
                }


                <div className="flex justify-center p1">
                    { (this.props.tab.length > 0) ?
                        <Button variant="primary" onClick={this.handleShowVerify}>
                            Pay my bill
                        </Button>
                    : ""
                    }

                    {/* VERIFICATION popup to make sure customer wants to end their TAB and didn't misclick */}
                    <Modal show={this.state.showVerify} onHide={this.handleCloseVerify} centered>
                        <Modal.Header>
                            <Modal.Title>Thank you for coming!</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Are you sure you're finished with your dining experience?</Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" onClick={this.handleShowTip}>
                                Yes - charge account
                            </Button>
                            <Button variant="secondary" onClick={this.handleCloseVerify}>
                                Not Yet
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    {/* TIPPING the staff */}
                    <Modal show={this.state.showTip} onHide={this.handleCloseTip}>
                        <Modal.Header>
                            <Modal.Title>Thank you for coming!</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>We hope you had an enjoyable experience with us today.</Modal.Body>
                        <h5>Show support with a tip</h5>
                        {/* <div className="staff-tile modal-body">
                            <img className="staff-image" src="/MosmanRowers-bg.jpg" alt="restaurant" />
                            <div className="staff-info">
                                <p>Mosman Rowers</p>
                                <Rating name="restaurant" defaultValue={0} size="small" />
                            </div>
                        </div> */}
                        {/* List of staff that served the customer on this table */}
                        {this.state.staff.map(el => 
                            <Modal.Body className="staff-tile text-left" key={el.name}>
                                <img className="staff-image" src={el.img} alt="staff" />
                                <div className="staff-info">
                                    <p><span className="bold">{el.name}</span> - {el.role}</p>
                                    <Rating name={el.name} defaultValue={0} size="small" />
                                </div>
                                <div className="staff-tips">
                                    <ToggleButtonGroup
                                    value={el.tip}
                                    exclusive
                                    onChange={this.handleTip}
                                    aria-label="tip"
                                    name={el.name}
                                    >
                                        <ToggleButton value={2} name={el.name} aria-label="0">
                                            $2
                                        </ToggleButton>
                                        <ToggleButton value={4} name={el.name} aria-label="3">
                                            $4
                                        </ToggleButton>
                                        <ToggleButton value={7} name={el.name} aria-label="5">
                                            $7
                                        </ToggleButton>
                                        <ToggleButton value={10} name={el.name} aria-label="10">
                                            $10
                                        </ToggleButton>
                                        <ToggleButton value={15} name={el.name} aria-label="15">
                                            $15
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                </div>
                            </Modal.Body>            
                        )}
                        <Modal.Footer>
                            <div className="total-bill">Total bill: <span className="color-main">${this.props.totalTabCost.toFixed(2)}</span> + <span className="color-main-dark">${this.state.staff.reduce((acc, cv) => acc + cv.tip, 0)} tips</span></div>
                            <Button variant="primary" onClick={this.handlePayTip}>
                                Finish
                            </Button>
                            {/* <Button variant="secondary" onClick={this.handleCloseTip}>
                                Cancel
                            </Button> */}
                        </Modal.Footer>
                    </Modal>

                    {/* FAREWELL and thank you screen. Redirects to main menu page */}
                    <Modal show={this.state.showFarewell} onHide={this.handleCloseFarewell} centered>
                        <Modal.Header>
                            <Modal.Title>Thank you for coming!</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="bold">
                            A total of <span className="color-main">${(this.props.totalTabCost + this.state.staff.reduce((acc, cv) => acc + cv.tip, 0)).toFixed(2)}</span> was successfully charged to your account.
                        </Modal.Body>
                        <Modal.Body>
                            We hope you enjoyed our hospitality. See you again soon!
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.handleCloseFarewell}>
                                Exit
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </section>
        );
    }
}

export default Table;