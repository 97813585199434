import React, { Component } from "react";
import './MenuItem.css';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// Menu list showing all the items
class MenuItem extends Component {

  constructor(props) {
    super(props)

    let URLid = props.match.params.id; //The URL id used to identify the menu food item
    let orders = props.orders; //Current array of already ordered food
    let menu = JSON.parse(JSON.stringify(props.menu)); //deep copy of the current menu
    let menuItem;
    let orderStatus = false;

    if (orders.filter(food => food.id === URLid)[0]) { //Has this food item already been ordered?
      menuItem = orders.filter(food => food.id === URLid)[0];
      orderStatus = true;
    } 
    else
    {
      if (URLid[0] === "b") {
          menuItem = menu['Breakfast'].filter(el => el.id === URLid)[0];
          menuItem.quantity = 1;
      } else if (URLid[0] === "l") {
          menuItem = menu['Little Rowers'].filter(el => el.id === URLid)[0];
          menuItem.quantity = 1;
      } else {
          menuItem = menu['Drinks'].filter(el => el.id === URLid)[0];
          menuItem.quantity = 1;
      }
    }

    this.state = {
      amount: menuItem.quantity,
      orderItem: menuItem,
      ordered: orderStatus
    }

    this.addAmount = this.addAmount.bind(this)
    this.removeAmount = this.removeAmount.bind(this)
    this.goBack = this.goBack.bind(this)
    this.updateOrderFunction = this.updateOrderFunction.bind(this)
  }

  addAmount = () => {
    let newAmount = this.state.amount + 1;
    // let newItem = this.state.orderItem;
    // newItem.quantity = newAmount;
    this.setState({
      amount: newAmount,
      // orderItem: newItem
    })
  }

  removeAmount = () => {
    if (this.state.amount > 0) {
      let newAmount = this.state.amount - 1;
      // let newItem = this.state.orderItem;
      // newItem.quantity = newAmount;
        this.setState({
          amount: newAmount,
          // orderItem: newItem
        })
      }
  }

  goBack = () => {
      this.props.history.goBack();
  }

  updateOrderFunction = () => {
    let newItem = this.state.orderItem;
    newItem.quantity = this.state.amount;
    this.setState({
      orderItem: newItem
    })

    this.props.updateOrder(this.state.orderItem);
    this.props.history.goBack();
  }
 
  render() {
    // console.log("The Menu: ", this.props.menu)
    // console.log("The state: ", this.state)
    let orderButton;
    if (this.state.ordered) {
      if (this.state.amount === this.state.orderItem.quantity) {
        orderButton = <Button variant="secondary">Current Order</Button>
      } else if (this.state.amount === 0) {
        orderButton = <Button variant="danger" onClick={() => {this.updateOrderFunction()}} ><img className="inline-icon" src="/trash.png" alt="trash-icon"/>Remove Order</Button>
      } else {
        orderButton = <Button variant="primary" onClick={() => {this.updateOrderFunction()}} ><img className="inline-icon" src="/pencil-white.png" alt="pencil-icon"/>Update Order</Button>
      }
    } else {
      if (this.state.amount === 0) {
        orderButton = <Button variant="secondary" >Add an amount</Button>
      } else {
        orderButton = <Button variant="success" onClick={() => {this.updateOrderFunction()}} ><img className="inline-icon" src="/plus-white.png" alt="plus-icon"/>Add to Order</Button>
      }
    }

    return (
      <section className="menuItem">
        <div className="header">
          <Row className="">
            <Col xs={3} className="justify-center">
              <img className="goBack arrow" onClick={this.goBack} src='/left-arrow-green.png' alt="back-arrow"/>
            </Col>
            <Col xs={6} className="menuItem-name">
                <h1 className="">{ this.state.orderItem.name }</h1>
            </Col>
            <Col xs={3} className="justify-center">
                <h5 className="">${ this.state.orderItem.price.toFixed(2) }</h5>
            </Col>
          </Row>
        </div>

        <div className="menuItem-info">
          <div className="menuItem-image" >
            <img className="fadeIn" src={this.state.orderItem.url} alt="food-preview"/>
          </div>
          { (this.state.orderItem.description) ?
            <p>{ this.state.orderItem.description }</p>
          :
          ''}
        </div>

        <div className="menuItem-counter">
          <img src="/left-arrow.png" className="left-arrow" alt="left-arrow" onClick={this.removeAmount}/>
          <h2 className="menuItem-amount">{ (this.state.amount) }</h2>
          <img src="/right-arrow.png" className="right-arrow" alt="right-arrow" onClick={this.addAmount}/>
        </div>

        <div className="menuItem-confirm">
            { orderButton }
        </div>
      </section>
    );
  }
}

export default MenuItem;