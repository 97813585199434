import React, { Component } from "react";
import './OrderStatus.css';

import ProgressBar from 'react-bootstrap/ProgressBar'

class OrderStatus extends Component {

    state = {
        counter: 0
    }

    componentDidMount() {
        this.interval = setInterval(() => this.setState({counter: this.props.order.counter}), 250)
    }
    componentWillUnmount() {
        clearInterval(this.interval)
    }

    render() {
        return (
            (this.state.counter <= 25) ? 
            <div className="order-progress">
                <small className="bold">New Order Received</small>
                <ProgressBar animated variant="danger" now={this.props.order.counter} />
            </div>
            :
            (this.state.counter <= 50) ?
            <div className="order-progress">
                <small className="bold">Being Prepared</small>
                <ProgressBar animated variant="warning" now={this.props.order.counter} />
            </div>
            :
            (this.state.counter <= 75) ?
            <div className="order-progress">
                <small className="bold">Cooking</small>
                <ProgressBar animated now={this.props.order.counter} />
            </div>
            :
            (this.state.counter < 100) ?
            <div className="order-progress">
                <small className="bold">Ready to serve</small>
                <ProgressBar animated variant="success" now={this.props.order.counter} />
            </div>
            :
            <div className="order-progress">
                <small className="bold">Enjoy!</small>
                <ProgressBar variant="success" now={this.props.order.counter} />
            </div>
        );
    }
}

export default OrderStatus;